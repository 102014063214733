jQuery(document).ready(function ($) {

	//var num = 800; //number of pixels before modifying styles
	var num = $( window ).height(); //number of pixels before modifying styles

	$(window).bind('scroll', function () {
		if ($(window).scrollTop() > num) {
			$('.site-header').addClass('sticky');
		} else {
			$('.site-header').removeClass('sticky');
		}
	});


	// menu click event
	$('.mobileBtn').click(function() {
		$(this).toggleClass('act');
			if($(this).hasClass('act')) {
				$('.main-navigation').addClass('act');
				$('body').addClass('mobnav-active');
			}
			else {
				$('.main-navigation').removeClass('act'); 
				$('body').removeClass('mobnav-active'); 
			}
	});

	$('.desktopBtn').click(function() {
		$(this).toggleClass('act');
			if($(this).hasClass('act')) {
				$('body').removeClass('donate-active'); 
				$('body').addClass('nav-active');
			}
			else {
				$('body').removeClass('nav-active'); 
				$('body').removeClass('donate-active'); //use this to close donate too
				//$('.donateBtn').removeClass('act');  //use this to close donate too
			}
	});

	/* $('.donateBtn').click(function(event) {
		event.preventDefault();
		$(this).toggleClass('act');
			if($(this).hasClass('act')) {
				$('body').removeClass('nav-active'); 
				$('body').addClass('donate-active');
				$('.desktopBtn').addClass('act');
			}
			else {
				$('body').removeClass('donate-active'); 
			}
	}); */ 

	/* Side button popover */
	$('.mailing').addClass("active");
	$('.mailing-butt').on('click', function() {
		$('.mailing').toggleClass("active");
		/*hide after set long time */
		setTimeout(function(){ 
			$('.mailing').toggleClass("active");
		}, 30000);
	});
	$('.closer').on('click', function() {
		$('.mailing').toggleClass("active");
	});
	/* hid after initial load */
	setTimeout(function(){ 
		$('.mailing').removeClass("active");
	}, 8000);


	/*Video animation */
	setInterval(function(){ 
		$('.ani').addClass("hide");
		$('.vid').addClass("show");
	}, 6000);

	/*events image swaap*/
	$( ".event-item" ).mouseover(function() {
		var d_activeimg = $(this).attr("data-id"); 
		$(".wp-post-image").removeClass('active');
		$(".image-"+d_activeimg).addClass('active');
	});

	/* Main mobile menu toggle */
	$( ".menu-item-has-children a" ).click(function(event) {
		event.preventDefault();
		if ( $(this).hasClass( "active" ) ) {
			$(this).removeClass("active");
			$('.sub-menu').removeClass("active");
			$(this).next( ".sub-menu" ).slideToggle( "slow", function() {
			});
		} else {
			$('.sub-menu').slideUp();
			$('.sub-menu').removeClass("active");
			$('.menu-item-has-children a').removeClass("active");
			$(this).toggleClass("active");
			$(this).next( ".sub-menu" ).slideToggle( "slow", function() {
			});
			$(".sub-menu a").unbind('click').click(); 
		}
	});

	/* Event filter toggle */
	$( ".filter" ).click(function(event) {
		event.preventDefault();
		/* close others */
		$( ".filter-options.active" ).slideToggle( "slow", function() {
		});
		$( ".filter-options.active" ).removeClass('active');
		$('.filter').not(this).each(function(){
			$(this).removeClass('active');
			console.log('alan');
		});
		/* open clicked */
		$(this).toggleClass("active");
		$(this).next( ".filter-options" ).toggleClass('active');
		$(this).next( ".filter-options" ).slideToggle( "slow", function() {
		});
	});
	$( ".filter-options a" ).click(function(event) {
		event.preventDefault();
		var title = $(this).html()
		$(this).parent().parent().find('li a').removeClass("active");
		$(this).addClass("active");
		$(this).parent().parent().parent().find( ".filter" ).removeClass("active");
		$(this).parent().parent().parent().find( ".filter" ).html(title);
		$(this).closest( ".filter-options" ).removeClass('active');
		$(this).closest( ".filter-options" ).slideToggle( "slow", function() {

		});
	});
 
	
	$('.report-list_item').on('click', function() {
		var reports = $(this).attr("data-slug"); 
		$('.report').removeClass("d-flex");
		$('.'+reports).addClass("d-flex");
	});

	/* GRANTS FILTER */
	$('.grant-list_item').on('click', function() {
		var vartype = $('.vartype li a.active').data('slug');
		var varyear = $('.varyear li a.active').data('slug');
		var varstate = $('.varstate li a.active').data('slug');
		var varcan = $('.varcan li a.active').data('slug');
		var varsearch = $('#grantsearch').val();

		$.ajax({
		type: 'POST',
		url: '/wp-admin/admin-ajax.php',
		dataType: 'html',
		data: {
			action: 'filter_grants',
			category: vartype,
			year: varyear,
			state: varstate,
			cancer: varcan,
			search: varsearch,
		},
		success: function(res) {
			$('.grants-list').html(res);
		}
		})
	});
	$('#searchgrants').on('submit', function(e) {
		e.preventDefault();
		var vartype = $('.vartype li a.active').data('slug');
		var varyear = $('.varyear li a.active').data('slug');
		var varstate = $('.varstate li a.active').data('slug');
		var varcan = $('.varcan li a.active').data('slug');
		var varsearch = $('#grantsearch').val();

		$.ajax({
		type: 'POST',
		url: '/wp-admin/admin-ajax.php',
		dataType: 'html',
		data: {
			action: 'filter_grants',
			category: vartype,
			year: varyear,
			state: varstate,
			cancer: varcan,
			search: varsearch,
		},
		success: function(res) {
			$('.grants-list').html(res);
		}
		})
	});


	/* EVENTS CAL FILTER */
	$('.cat-list_item').on('click', function() {
		var vartype = $('.vartype li a.active').data('slug');
		var varyear = $('.varyear li a.active').data('slug');
		var varstate = $('.varstate li a.active').data('slug');

		console.log (varyear);
	
		$.ajax({
		type: 'POST',
		url: '/wp-admin/admin-ajax.php',
		dataType: 'html',
		data: {
			action: 'filter_events',
			category: vartype,
			year: varyear,
			state: varstate,
		},
		success: function(res) {
			$('.events-listing').html(res);
		}
		})
	});



	/* Footer mobile menu toggle */
	$( ".site-footer .h7" ).click(function() {
		$(this).toggleClass("active");
		$(this).next( ".footer-menu" ).slideToggle( "slow", function() {
		  // Animation complete.
		});
	});


	/* SLIDERS */
	// Hero
	$(".hero-slider").slick({
		autoplay: true,
		autoplay: true,
		autoplaySpeed: 5000,
		dots: false,
		arrows: true
	});

	// Stories
	$(".stories-slider").slick({
		dots: false,
		arrows: true,
		infinite: true,
		centerMode: true,
		speed: 800,
		slidesToShow: 1,
		slidesToScroll: 1,
		mobileFirst: true,
		responsive: [
			/*
		   {
			  breakpoint: 800,
			  settings: "unslick"
		   },
		   */
		   {
				breakpoint: 1200,
				settings: {
				centerMode: false,
				slidesToShow: 3,
				slidesToScroll: 1
				}
			},
		   {
			   breakpoint: 600,
			   settings: {
			   slidesToShow: 2,
			   slidesToScroll: 1
			   }
		   }
		]
	});

	// Events
	$(".events-slider").slick({
		dots: false,
		arrows: true,
		infinite: true,
		centerMode: true,
		speed: 800,
		slidesToShow: 1,
		slidesToScroll: 1,
		mobileFirst: true,
		responsive: [
			/*
			{
				breakpoint: 800,
				settings: "unslick"
			},
			*/
			{
				breakpoint: 1200,
				settings: {
				centerMode: false,
				slidesToShow: 5,
				slidesToScroll: 1
				}
			},
			{
				breakpoint: 600,
				settings: {
				slidesToShow: 2,
				slidesToScroll: 1
				}
			}
		]
	});

	/* stories-mini */
	$(".stories-mini").slick({
		infinite: true,
		speed: 800,
		slidesToShow: 2,
		slidesToScroll: 1,
		mobileFirst: true,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
				slidesToShow: 3,
				slidesToScroll: 1
				}
			},
			{
				breakpoint: 600,
				settings: {
				slidesToShow: 2,
				slidesToScroll: 1
				}
			}
			]
	});	

	// widgets gallery
	$(".widgetslick").each(function(index){
		var dataid = $(this).attr("data-id");

		var $slider = $('.'+dataid+'.widgetslick');

		$slider.slick({
			dots: false,
			infinite: true,
			centerMode: true,
			speed: 800,
			slidesToShow: 1,
			slidesToScroll: 1,
			mobileFirst: true,
			cssEase: 'linear',
			variableWidth: true,
			responsive: [
				{
				  breakpoint: 1024,
				  settings: {
					centerMode: false,
					dots: true,
					slidesToShow: 3,
					slidesToScroll: 1
				  }
				},
				{
				  breakpoint: 600,
				  settings: {
					centerMode: false,
					dots: true,
					slidesToShow: 2,
					slidesToScroll: 1
				  }
				}
			  ]
		});

	});

	// Icons gallery - icons-carousel
	$(".icons-carousel .icons").each(function(index){
		var dataid = $(this).attr("data-id");

		var $slider = $('.'+dataid+'.icons');

		$slider.slick({
			dots: false,
			infinite: true,
			centerMode: true,
			speed: 800,
			slidesToShow: 1,
			slidesToScroll: 1,
			mobileFirst: true,
			variableWidth: true,
			prevArrow: $('.icon-prev'),
      		nextArrow: $('.icon-next'),
			responsive: [
				{
				  breakpoint: 1024,
				  settings: {
					slidesToShow: 4,
					slidesToScroll: 1
				  }
				},
				{
				  breakpoint: 600,
				  settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				  }
				},
				{
				  breakpoint: 100,
				  settings: "unslick"
				}
			  ]
		});

	});	

	// Stats
	$(".stats-slider").each(function(index){
		var dataid = $(this).attr("data-id");

		var $slider = $('.'+dataid+'.stats-slider');

		$slider.slick({
			dots: false,
			arrows: false,
			infinite: true,
			autoplay: true,
			autoplaySpeed: 1200,
			centerMode: false,
			speed: 1200,
			slidesToShow: 1,
			slidesToScroll: 1,
			mobileFirst: true,
			variableWidth: false,
			vertical: false,
			fade: true,
			pauseOnFocus: false,
			pauseOnHover: false,
			cssEase: 'ease-in-out'
		});

	});		

	// Reports
	$(".reports-slider").each(function(index){
		var dataid = $(this).attr("data-id");

		var $slider = $('.'+dataid+'.reports-slider');

		$slider.slick({
			dots: false,
			arrows: true,
			infinite: true,
			autoplay: false,
			centerMode: false,
			speed: 800,
			slidesToShow: 3,
			slidesToScroll: 1,
			mobileFirst: true,
			variableWidth: true,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
					slidesToShow: 6,
					slidesToScroll: 1
					}
				},
				{
					breakpoint: 600,
					settings: {
					slidesToShow: 3,
					slidesToScroll: 1
					}
				}
			]
		});

	});		


	/* Staff widget */
	$(".staff-slider").slick({
		infinite: true,
		speed: 800,
		slidesToShow: 2,
		slidesToScroll: 1,
		mobileFirst: true,
		cssEase: 'linear',
		responsive: [
			{
				breakpoint: 1024,
				settings: {
				slidesToShow: 4,
				slidesToScroll: 1
				}
			},
			{
				breakpoint: 600,
				settings: {
				slidesToShow: 2,
				slidesToScroll: 1
				}
			}
		]
	});

	// Staff Leadership page
	$(".staffslider").each(function(index){
		var dataid = $(this).attr("data-id");

		var $slider = $('.'+dataid+'.staffslider');

		$slider.slick({
			infinite: true,
			speed: 800,
			slidesToShow: 2,
			slidesToScroll: 1,
			mobileFirst: true,
			cssEase: 'linear',
			responsive: [
				{
					breakpoint: 1024,
					settings: {
					slidesToShow: 4,
					slidesToScroll: 1
					}
				},
				{
					breakpoint: 600,
					settings: {
					slidesToShow: 2,
					slidesToScroll: 1
					}
				}
				]
		});

	});	

	/* Grants */
	$(".grants-slider").slick({
		infinite: true,
		centerMode: true,
		speed: 800,
		slidesToShow: 1,
		slidesToScroll: 1,
		mobileFirst: true,
		cssEase: 'linear',
		responsive: [
			{
				breakpoint: 1024,
				settings: {
				centerMode: false,
				slidesToShow: 4,
				slidesToScroll: 1
				}
			},
			{
				breakpoint: 600,
				settings: {
				centerMode: false,
				slidesToShow: 2,
				slidesToScroll: 1
				}
			}
			]
	});	

	/* Partners */
	$(".partners-slider").slick({
		infinite: true,
		dots: false,
		arrows: false,
		centerMode: false,
		speed: 800,
		slidesToShow: 3,
		slidesToScroll: 1,
		mobileFirst: true,
		autoplay: true,
		autoplaySpeed: 2000,
		variableWidth: true,
		pauseOnFocus: false,
		pauseOnHover: false,
		responsive: [
			{
				breakpoint: 960,
				settings: {
					slidesToShow: 6,
					dots: false,
					centerMode: false
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 4,
					dots: false,
					centerMode: false
				}
			}
			]
	});		

	AOS.init({
		duration: 800,
        delay: 200
    });

	$.modal.defaults = {
		
		closeExisting: true,    // Close existing modals. Set this to false if you need to stack multiple modal instances.
		escapeClose: true,      // Allows the user to close the modal by pressing `ESC`
		clickClose: true,       // Allows the user to close the modal by clicking the overlay
		closeText: 'Close',     // Text content for the close <a> tag.
		closeClass: '',         // Add additional class(es) to the close <a> tag.
		showClose: true,        // Shows a (X) icon/link in the top-right corner
		/*
		modalClass: "modal",    // CSS class added to the element being displayed in the modal.
		blockerClass: "modal",  // CSS class added to the overlay (blocker).
		*/
	  	/*
		// HTML appended to the default spinner during AJAX requests.
		spinnerHtml: '<div class="rect1"></div><div class="rect2"></div><div class="rect3"></div><div class="rect4"></div>',
		showSpinner: true,      // Enable/disable the default spinner during AJAX requests.
		*/
		fadeDuration: 300,     // Number of milliseconds the fade transition takes (null means no transition)
		fadeDelay: .5          // Point during the overlay's fade-in that the modal begins to fade in (.5 = 50%, 1.5 = 150%, etc.)
	};

	/* adjust margins for widgets */
	$( ".icons-carousel" ).prev( ".wdgt" ).addClass( "icons-carousel-next" );
	$( ".icons-carousel" ).next( ".wdgt" ).addClass( "icons-carousel-prev" );
	$( ".wdgt-featured" ).prev( ".wdgt" ).addClass( "wdgt-featured-next" ); 

}); 

// initialize BiggerPicture
let bp = BiggerPicture({
	target: document.body
  });
  
  // grab image links
  let links = document.querySelectorAll("a.vimeo");
  
  // add click listener on links to open BiggerPicture
  for (let link of links) {
	link.addEventListener("click", openBiggerPicture);
  }
  
  // function to open BiggerPicture
  function openBiggerPicture(e) {
	e.preventDefault();
	bp.open({
	  items: e.currentTarget,
	  el: e.currentTarget
	});
  }


/* TABS */
/* https://codepen.io/tutsplus/pen/RQRNxR */
const tabLinks = document.querySelectorAll(".tabs a");
const tabPanels = document.querySelectorAll(".tabs-panel");

for (let el of tabLinks) {
  el.addEventListener("click", e => {
    e.preventDefault();

    document.querySelector(".tabs li.active").classList.remove("active");
    document.querySelector(".tabs-panel.active").classList.remove("active");

    const parentListItem = el.parentElement;
    parentListItem.classList.add("active");
    const index = [...parentListItem.parentElement.children].indexOf(parentListItem);

    const panel = [...tabPanels].filter(el => el.getAttribute("data-index") == index);
    panel[0].classList.add("active");
    });
  }
